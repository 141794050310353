import React from "react";
import LoadingOverlay from "react-loading-overlay";
import NumberFormat from "react-number-format";
import TableTopheader from "../../../common/components/TableTopHeader";

const PasswordSettingss = () => {
    return (
        // <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
        <section className="cont-ara">
            <div className="list-area">
                <div className="lst-bt-nav">
                    <div className="table table-ara">
                        <div className="top-fil-ara title-btn-wrapper">
                            <div className="table-heading cap">
                                <h4>Password Settings</h4>
                            </div>
                        </div>{" "}
                        <div className="right-cont"></div>
                        <div className="list-sec">
                            <div className="table-section">
                                <table className="table table-bordered common-table ">
                                    <thead>
                                        <tr>
                                            <th className="build-add">
                                                <span className="high-lighter">
                                                    <span className="">Type</span>
                                                </span>
                                            </th>
                                            <th className="build-add">
                                                <span className="high-lighter">
                                                    <span className="">Option</span>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="high-lighter">
                                                    <span className=""> Password Structure</span>
                                                </span>
                                            </td>
                                            <td className="fy-dtl">
                                                <div className="button-group">
                                                    <div className="custom-control custom-checkbox col-md-12">
                                                        <input className="custom-control-input" type="checkbox" id="specialCharacterCheckbox" />

                                                        <label className="custom-control-label" htmlFor="specialCharacterCheckbox">
                                                            At least one special character
                                                        </label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox col-md-12">
                                                        <input className="custom-control-input" type="checkbox" id="numberCheckbox" />

                                                        <label className="custom-control-label" htmlFor="numberCheckbox">
                                                            At least one number
                                                        </label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox col-md-12">
                                                        <input className="custom-control-input" type="checkbox" id="uppercaseCheckbox" />

                                                        <label className="custom-control-label" htmlFor="uppercaseCheckbox">
                                                            At least one uppercase and lowercase letters
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="high-lighter">
                                                    <span className=""> Minimum Characters</span>
                                                </span>
                                            </td>
                                            <td className="fy-dtl">
                                                <div className="form-group">
                                                    <NumberFormat
                                                        autoComplete={"nope"}
                                                        className="form-control"
                                                        // placeholder="CRV"
                                                        name="char_limit"
                                                        // value={char_limit}
                                                        displayType={"input"}
                                                        // onValueChange={values => this.minChar(values.value, "char_limit")}
                                                    />
                                                </div>
                                                {/* <div className="button-group" style={{ margin: "initial" }}>
                                                    <NumberFormat
                                                        autoComplete={"nope"}
                                                        className="custom-input form-control"
                                                        // placeholder="CRV"
                                                        name="char_limit"
                                                        // value={char_limit}
                                                        displayType={"input"}
                                                        // onValueChange={values => this.minChar(values.value, "char_limit")}
                                                    />
                                                </div> */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="high-lighter">
                                                    <span className=""> Aging</span>
                                                </span>
                                            </td>
                                            <td className="fy-dtl">
                                                <div className="form-group">
                                                    <NumberFormat
                                                        autoComplete={"nope"}
                                                        className="custom-input form-control"
                                                        placeholder="Months"
                                                        name="aging"
                                                        // value={aging}
                                                        displayType={"input"}
                                                        suffix={" Months"}
                                                        // onValueChange={values => this.Aging(values.value, "aging")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span className="high-lighter">
                                                    <span className=""> Login Attempts Limit</span>
                                                </span>
                                            </td>
                                            <td className="fy-dtl">
                                                <div className="form-group">
                                                    <NumberFormat
                                                        autoComplete={"nope"}
                                                        className="custom-input form-control"
                                                        // placeholder="CRV"
                                                        name="Maximum Login Attempts Limit"
                                                        // value={login_limit}
                                                        displayType={"input"}
                                                        // onValueChange={values => this.loginLimits(values.value, "login_limit")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* footer  */}
                    {/* <div className="fot-nav"></div> */}
                </div>
            </div>
        </section>
        // </LoadingOverlay>
    );
};

export default PasswordSettingss;
