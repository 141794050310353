import React from "react";
import { useSelector } from "react-redux";
import EventBreakdownChart from "./EventBreakdownCharts";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../../common/components/Loader";

export const EventBreakdownChild = ({
    isFullScreen,
    fullScreenChart,
    smartReportHandler,
    fullViewHandler,
    section,
    handleViewBreakdown,
    sectionData
}) => {
    const { getEventBreakdownChartByHierarchy } = useSelector(state => state.dashboardReducer);
    const formatData = data => {
        let formattedData = [];
        if (Object.keys(data)?.length) {
            Object.entries(data).forEach(([key, value]) => formattedData.push({ status: key, count: value }));
        }
        return formattedData;
    };
    return (
        <div class={`w-100`}>
            <LoadingOverlay active={getEventBreakdownChartByHierarchy[section.key]?.isLoading} spinner={<Loader />}>
                <div className={`graph-otr row-scroll ${isFullScreen ? "row-scroll-full-screen" : ""} arrow__top`}>
                    {getEventBreakdownChartByHierarchy[section.key]?.event_break_down?.length ? (
                        getEventBreakdownChartByHierarchy[section.key].event_break_down
                            .filter(item => (sectionData.expanded && sectionData.childId ? item.id === sectionData.childId : true))
                            .map(item => (
                                <>
                                    {((isFullScreen && fullScreenChart === `event_breakdown_${item.name.replace(" ", "_")}`) || !isFullScreen) && (
                                        <EventBreakdownChart
                                            eventBreakdownData={formatData(item.data)}
                                            toggleSmartReportModal={smartReportHandler}
                                            fullViewHandler={fullViewHandler}
                                            isFullScreen={isFullScreen}
                                            fullScreenChart={fullScreenChart}
                                            childName={`${item.name} (${section.label})`}
                                            childId={item.id}
                                            childKey={`event_breakdown_${item.name.replace(" ", "_")}`}
                                            isChildBreakdown
                                            sectionKey={section.key}
                                            sectionParam={section.paramKey}
                                            expanded={sectionData.expanded}
                                            handleViewBreakdown={handleViewBreakdown}
                                        />
                                    )}
                                </>
                            ))
                    ) : (
                        <div class="graph-bx col-12 ">
                            <div class="graph-content nodata-col">
                                <div>No Data Available</div>
                            </div>
                        </div>
                    )}
                </div>
            </LoadingOverlay>
        </div>
    );
};
