import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import highchartsMore from "highcharts/highcharts-more";
import GridLight from "highcharts/themes/grid-light";
import _ from "lodash";
import * as htmlToImage from "html-to-image";
import moment from "moment";

import { graphDummyData } from "../../../../config/utils";

highchartsMore(Highcharts);

highcharts3d(Highcharts);

GridLight(Highcharts);

class EventBreakdownChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: null,
            chartType: "3D",
            legendData: [],
            totalCount: "",
            showLegendsDetails: false
        };
    }
    componentDidMount = async () => {
        await this.renderChartData();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (!_.isEqual(prevProps.eventBreakdownData, this.props.eventBreakdownData)) {
            await this.renderChartData();
        }
        if (prevState.chartType !== this.state.chartType) {
            await this.renderChartData();
        }
        if (prevProps.isFullScreen !== this.props.isFullScreen) {
            if (!this.props.isFullscreen) {
                Highcharts.charts.map(chart => {
                    while (chart && chart.series && chart.series.length > 0) chart.series[0].remove(true);
                });
            }
            Highcharts.charts.map(chart => {
                chart && chart.reflow();
            });
            await this.renderChartData();
        }
    };

    getColor = type => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "Non-Compliant":
                return "#FF8000";
            case "Incomplete":
                return "#FF0000";
            case "Overdue":
                return "#F59089";
            case "In Threshold":
                return "#f2f247";
            case "Completed":
                return "#00FF00";
        }
    };

    replaceSpaceWithNewLine = str => {
        return str;
        return str.replace(" ", "<br>");
    };

    renderChartData = async () => {
        const { eventBreakdownData, toggleSmartReportModal, isChildBreakdown, childId, sectionParam } = this.props;
        let tempData = graphDummyData.eventBreakdownData.map(item => {
            return { label: this.replaceSpaceWithNewLine(item.status), name: item.status, y: item.count, color: this.getColor(item.status) };
        });
        let total = 0;
        if (eventBreakdownData.length) {
            tempData = eventBreakdownData.map(item => {
                total = total + item.count;
                return { label: this.replaceSpaceWithNewLine(item.status), name: item.status, y: item.count, color: this.getColor(item.status) };
            });
        }
        let legentList = tempData;
        const { chartType } = this.state;
        let dataSource = {};
        dataSource = {
            chart: {
                type: "pie",
                backgroundColor: "#FFFFFF",
                options3d: {
                    enabled: chartType == "3D" ? true : false,
                    alpha: 45,
                    beta: 0
                },
                style: {
                    fontFamily: "Poppins, sans-serif"
                }
            },
            title: {
                text: ""
            },
            colors: ["#058DC7", "#50B432", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"],
            accessibility: {
                point: {
                    valueSuffix: "%"
                }
            },
            tooltip: {
                pointFormat: "<b>{point.percentage:.1f}%({point.y})</b>"
            },
            plotOptions: {
                pie: {
                    innerSize: chartType == "doughnut2d" || chartType == "doughnut3d" ? 100 : 0,
                    allowPointSelect: false,
                    cursor: "pointer",
                    depth: 30,
                    showInLegend: this.props.isFullScreen ? true : false,
                    backgroundColor: "#FFFFFF",
                    dataLabels: {
                        enabled: true,
                        format: "{point.label}"
                    },
                    point: {
                        events: {
                            legendItemClick: event => {
                                if (event.target.visible) {
                                    total = total - event.target.options.y;
                                    legentList = legentList && legentList.filter(legend => legend.name != event.target.options.name);
                                } else {
                                    total = total + event.target.options.y;
                                    legentList.push({ name: event.target.options.name, y: event.target.options.y });
                                }
                                this.setState({
                                    legendData: legentList,
                                    totalCount: total
                                });
                            }
                        }
                    }
                },
                series: {
                    cursor: "pointer",
                    events: {
                        click: event => {
                            toggleSmartReportModal(
                                "event_breakdown",
                                event.point.name.toLowerCase().replace(/ /g, "_"),
                                isChildBreakdown ? event.point?.series?.options?.entityId : "",
                                sectionParam
                            );
                        }
                    },
                    startAngle: 90,
                    dataLabels: {
                        style: {
                            fontSize: 10
                        }
                    }
                }
            },
            series: [
                {
                    entityId: childId,
                    data: tempData,
                    animation: false
                }
            ]
        };
        await this.setState({
            dataSource,
            legendData: tempData,
            totalCount: total
        });
        window.Highcharts = Highcharts;
    };

    convertToImage = imgType => {
        const { isChildBreakdown, childKey, childName } = this.props;
        let user = localStorage.getItem("userName") || "";
        let name = `Event_Breakdown${isChildBreakdown ? `-${childName}` : ""}-${user}-${moment().format("MM_DD_YYYY HH_mm_ss")}`;
        const divId = isChildBreakdown ? childKey : "chartItem";
        if (imgType == "jpeg") {
            htmlToImage.toJpeg(document.getElementById(divId), { quality: 0.95 }).then(function (dataUrl) {
                var link = document.createElement("a");
                link.download = `${name}.jpeg`;
                link.href = dataUrl;
                link.click();
            });
        } else if (imgType == "png") {
            htmlToImage.toPng(document.getElementById(divId), { quality: 0.95 }).then(function (dataUrl) {
                var link = document.createElement("a");
                link.download = `${name}.png`;
                link.href = dataUrl;
                link.click();
            });
        }
    };

    renderClass = () => {
        const { isFullScreen, fullScreenChart, isChildBreakdown, childKey } = this.props;
        if (isFullScreen) {
            // const key = isChildBreakdown ? childKey : "event_breakdown";
            if (fullScreenChart === childKey || "event_breakdown") {
                return "col-12 graph-otr-large";
            } else {
                return "d-none";
            }
        } else {
            return "";
        }
    };

    toggleShowProfileDropDown = value => {
        const { showLegendsDetails } = this.state;
        this.setState({
            showLegendsDetails: value ? value : !showLegendsDetails
        });
    };

    render() {
        const { dataSource, chartType, legendData, totalCount, showLegendsDetails } = this.state;
        const {
            fullViewHandler,
            isFullScreen,
            eventBreakdownData,
            childName = "",
            isChildBreakdown,
            childKey = "",
            sectionKey,
            childId,
            handleViewBreakdown
        } = this.props;
        return (
            <div className={`graph-bx col-xl-4 col-lg-4 col-md-6 ${this.renderClass()}`}>
                <div className="graph-hd">
                    <strong>{childName || "Activity Event Breakdown"}</strong>
                    <div className="btn-grp">
                        {!isFullScreen ? (
                            <ul
                                className="prf-lst show-legends-eye"
                                onMouseEnter={() => this.toggleShowProfileDropDown(true)}
                                onMouseLeave={() => this.toggleShowProfileDropDown(false)}
                            >
                                <span className="material-icons">info</span>
                                {!isFullScreen && showLegendsDetails ? (
                                    <div className="dropdown-menu profile-view show" aria-labelledby="profile-info">
                                        <div className="dropdown-content">
                                            {legendData && legendData.length
                                                ? legendData.map(lData => {
                                                      return (
                                                          <button>
                                                              <span>
                                                                  <strong>{lData.name}</strong>
                                                              </span>
                                                              <span>
                                                                  {lData.y ? Number(((lData.y / totalCount) * 100).toFixed(1)) : 0}% ({lData.y})
                                                              </span>
                                                          </button>
                                                      );
                                                  })
                                                : null}
                                        </div>
                                        <button className="total-btn">
                                            <span>
                                                <strong>TOTAL</strong>
                                            </span>
                                            <span>{totalCount}</span>
                                        </button>
                                    </div>
                                ) : null}
                            </ul>
                        ) : null}
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenu2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {chartType}
                            </button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                <button className="dropdown-item" type="button" onClick={() => this.setState({ chartType: "2D" })}>
                                    2D
                                </button>
                                <button className="dropdown-item" type="button" onClick={() => this.setState({ chartType: "3D" })}>
                                    3D
                                </button>
                            </div>
                        </div>
                        <div className="dropdown dot-drop">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenu2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            ></button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                <button className="dropdown-item" type="button" onClick={() => this.convertToImage("png")}>
                                    Export to PNG
                                </button>
                                <button className="dropdown-item" type="button" onClick={() => this.convertToImage("jpeg")}>
                                    Export to JPEG
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="btn-grp btn-grp-top ml-1 p-0">
                        <button className="btn" onClick={() => fullViewHandler(isChildBreakdown ? childKey : "event_breakdown")}>
                            <img src="images/restore.svg" alt="" />
                        </button>
                    </div>
                </div>
                <div className={`graph-content ${!eventBreakdownData.length ? "no-data-sctn" : ""}`} data-descr="No Activity To Report">
                    <div className="g-box" id={isChildBreakdown ? childKey : "chartItem"}>
                        {dataSource && dataSource.series && dataSource.series.length ? (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={dataSource}
                                containerProps={
                                    isFullScreen ? { style: { height: "500px", width: "100%" } } : { style: { height: "100%", width: "100%" } }
                                }
                                allowChartUpdate
                            />
                        ) : null}

                        {isFullScreen && dataSource && dataSource.series && dataSource.series.length ? (
                            <div className="chart-footer">
                                <div className="row">
                                    <div className="col-md-9 pr-0">
                                        <div className="result-list">
                                            <ul>
                                                {legendData && legendData.length
                                                    ? legendData.map(lData => {
                                                          return (
                                                              <li>
                                                                  <div className="otr">
                                                                      <strong>{lData.name}</strong>
                                                                      <p>
                                                                          {lData.y ? Number(((lData.y / totalCount) * 100).toFixed(1)) : 0}% (
                                                                          {lData.y})
                                                                      </p>
                                                                  </div>
                                                              </li>
                                                          );
                                                      })
                                                    : null}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="result-total">
                                            <strong>TOTAL = {totalCount}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {sectionKey !== "buildings" && (
                            <div className="collapse___button_otr">
                                <button
                                    className="collapse___button"
                                    data-toggle="collapse"
                                    aria-expanded={this.props.expanded ? "true" : "false"}
                                    onClick={() => handleViewBreakdown(childId, sectionKey)}
                                >
                                    View Breakdown
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default EventBreakdownChart;
