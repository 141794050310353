import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Highcharts from "highcharts";
import LoadingOverlay from "react-loading-overlay";

import actions from "./actions";
import EntityCount from "./components/EntityCount";
import EventBreakdownChart from "./components/charts/EventBreakdownCharts";
import CompletedEventBreakdownChart from "./components/charts/CompletedEventBreakdownChart";
import IncompletedEventBreakdownChart from "./components/charts/IncompleteEventsBreakdownChart";
import CalenderAnalysisChart from "./components/charts/CalenderAnalysisChart";
import CompletedWithFailuresChart from "./components/charts/CompletedWithFailures";
import { checkPermission } from "../../config/utils";
import Loader from "../common/components/Loader";
import Portal from "../common/components/Portal";
import TopSlider from "../common/components/TopSlider";
import SmartReportModal from "./components/SmartReportModal";
import { EventBreakdownChild } from "./components/charts/EventBreakdownChild";

const hierarchyLevels = [
    { key: "consultancies", label: "Consultancy", paramKey: "consultancy_ids" },
    { key: "clients", label: "Client", paramKey: "client_ids" },
    { key: "sectors", label: "Sector", paramKey: "sector_ids" },
    { key: "campuses", label: "Campus", paramKey: "campus_ids" },
    { key: "buildings", label: "Building", paramKey: "building_ids" }
];
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counts: [],
            event_breakdown: [],
            completed_events_breakdown: [],
            incomplete_events_breakdown: [],
            calendar_analysis: [],
            top_10_completed_with_failures: [],
            view: "trailing",
            isLoading: true,
            masterFilterList: [
                { key: "consultancies", label: "Consultancies" },
                { key: "clients", label: "Clients" },
                { key: "logbooks", label: "Logbooks" },
                { key: "sectors", label: "Sectors" },
                { key: "campus", label: "Campuses" },
                { key: "buildings", label: "Buildings" },
                { key: "building_types", label: "Building types" }
            ],
            selectedFiterDropdown: null,
            filterDriodownList: [],
            isLoadingDropdown: true,
            showSmartReportModal: false,
            chart: "",
            item: "",
            fullScreenChart: "",
            isFullScreen: false,
            chart_view: "average",
            isEventChildBreakdownVisible: {},
            shownChildLevels: {},
            currentBreakdown: { key: "", id: "" },
            selectedEntity: ""
        };
        this.collapseRefs = {};
        this.observers = {};
    }

    componentDidMount = async () => {
        await this.refreshDashboardData();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.masterFilters !== this.props.masterFilters) {
            await this.refreshDashboardData();
            this.setState({ currentBreakdown: {}, shownChildLevels: [] });
        }
        if (prevState.currentBreakdown !== this.state.currentBreakdown) {
            this.handleChildVisibilityChange();
        }
    };

    refreshDashboardData = async () => {
        this.props.setIsLoading(true);
        window.Highcharts = Highcharts;
        const { chart_view } = this.state;
        let master_filters = JSON.parse(localStorage.getItem("master_filters"));
        await this.props.getDashboardData({ ...master_filters, chart_view });
        const {
            dashboardReducer: {
                getDashboardDataResponse: {
                    counts = [],
                    event_breakdown = [],
                    completed_events_breakdown = [],
                    incomplete_events_breakdown = [],
                    calendar_analysis = [],
                    top_10_completed_with_failures = []
                }
            }
        } = this.props;
        await this.setState({
            counts,
            event_breakdown,
            completed_events_breakdown,
            incomplete_events_breakdown,
            calendar_analysis,
            top_10_completed_with_failures
        });
        this.props.setIsLoading(false);
    };

    toggleAnnualTrailing = async choice => {
        await this.setState({ view: choice, selectedFiterDropdown: null });
        await this.refreshDashboardData();
    };

    toggleChartView = async chart_view => {
        await this.setState({ chart_view });
        await this.refreshDashboardData();
    };

    smartReportHandler = async (chartType, selectedItem, entityId, entityType) => {
        await this.setState({
            chart: chartType,
            item: selectedItem,
            entityId,
            selectedEntity: entityType
        });
        this.toggleSmartReportModal();
    };

    toggleSmartReportModal = item => {
        const { showSmartReportModal } = this.state;
        this.setState({
            showSmartReportModal: !showSmartReportModal
        });
    };

    renderSmartReportModal = () => {
        const { showSmartReportModal, chart, item, view, entityId, selectedEntity } = this.state;
        if (!showSmartReportModal) return null;
        return (
            <Portal
                body={
                    <SmartReportModal
                        onCancel={this.toggleSmartReportModal}
                        chart={chart}
                        item={item}
                        view={view}
                        refreshDashboardData={this.refreshDashboardData}
                        tempFilters={this.getTempFilterParam(entityId, selectedEntity)}
                    />
                }
                onCancel={this.toggleSmartReportModal}
            />
        );
    };

    fullViewHandler = chartName => {
        this.setState({
            fullScreenChart: chartName,
            isFullScreen: !this.state.isFullScreen
        });
    };

    getTempFilterParam = (entityId, selectedEntity) => {
        let tempFilters = {};
        if (entityId) {
            if (selectedEntity) {
                tempFilters[selectedEntity] = [entityId];
            } else {
                const master_filters = JSON.parse(localStorage.getItem("master_filters"));
                const { consultancy_ids, client_ids, sector_ids, campus_ids, building_ids } = master_filters;
                if (building_ids?.length) {
                    tempFilters.building_type_ids = [entityId];
                } else if (campus_ids?.length) {
                    tempFilters.building_ids = [entityId];
                } else if (sector_ids?.length) {
                    tempFilters.campus_ids = [entityId];
                } else if (client_ids?.length) {
                    tempFilters.sector_ids = [entityId];
                } else if (consultancy_ids?.length) {
                    tempFilters.client_ids = [entityId];
                } else {
                    tempFilters.consultancy_ids = [entityId];
                }
            }
        }
        return tempFilters;
    };

    getBreakdownType = (section = "") => {
        const master_filters = JSON.parse(localStorage.getItem("master_filters"));
        const { consultancy_ids, client_ids, sector_ids, campus_ids, building_ids } = master_filters;
        let user_role = localStorage.getItem("user_role");

        let breakDownType = "";
        if (section && section !== "main") {
            breakDownType =
                section === "consultancies"
                    ? "clients"
                    : section === "clients"
                    ? "sectors"
                    : section === "sectors"
                    ? "campuses"
                    : section === "campuses"
                    ? "buildings"
                    : section === "buildings"
                    ? "building_types"
                    : "";
        } else {
            breakDownType = building_ids?.length
                ? "building_types"
                : campus_ids?.length
                ? "buildings"
                : sector_ids?.length
                ? "campuses"
                : client_ids?.length
                ? "sectors"
                : consultancy_ids?.length
                ? "clients"
                : user_role === "consultancy_user"
                ? "clients"
                : user_role === "client_user"
                ? "sectors"
                : "consultancies";
        }
        return breakDownType;
    };

    handleViewBreakdown = (id, key) => {
        const { shownChildLevels } = this.state;
        const breakDownType = this.getBreakdownType(key);

        if (shownChildLevels?.[breakDownType]?.visible) {
            // hide entity and its child entities
            let visibleChild = { ...shownChildLevels };
            let currentBreakdown = "";

            if (breakDownType === "consultancies") {
                currentBreakdown = "";
                visibleChild = {};
            } else if (breakDownType === "clients") {
                currentBreakdown = "consultancies";
                visibleChild = Object.fromEntries(
                    Object.entries(visibleChild).filter(
                        ([child]) => child !== "clients" && child !== "sectors" && child !== "campuses" && child !== "buildings"
                    )
                );
            } else if (breakDownType === "sectors") {
                currentBreakdown = "clients";
                visibleChild = Object.fromEntries(
                    Object.entries(visibleChild).filter(([child]) => child !== "sectors" && child !== "campuses" && child !== "buildings")
                );
            } else if (breakDownType === "campuses") {
                currentBreakdown = "sectors";
                visibleChild = Object.fromEntries(Object.entries(visibleChild).filter(([child]) => child !== "campuses" && child !== "buildings"));
            } else if (breakDownType === "buildings") {
                currentBreakdown = "campuses";
                visibleChild = Object.fromEntries(Object.entries(visibleChild).filter(([child]) => child !== "buildings"));
            }
            if (visibleChild[key]) {
                visibleChild[key] = { visible: true, expanded: false, childId: "" };
            }

            this.setState({ currentBreakdown: { key: currentBreakdown, id: "" }, shownChildLevels: visibleChild });
        } else {
            this.setState(prevState => ({
                currentBreakdown: { key: breakDownType, id },
                shownChildLevels: {
                    ...prevState.shownChildLevels,
                    [key]: { visible: true, expanded: true, childId: id },
                    [breakDownType]: { visible: true, expanded: false, childId: "" }
                }
            }));
        }
    };

    handleChildVisibilityChange = () => {
        const {
            currentBreakdown: { key, id }
        } = this.state;
        if (key) {
            this.getEventBreakdownByHierarchy(key, id);
        }
    };

    getEventBreakdownByHierarchy = (key, id) => {
        const { chart_view } = this.state;
        const master_filters = JSON.parse(localStorage.getItem("master_filters"));
        let entityParams = {};
        if (id && key !== "consultancies") {
            switch (key) {
                case "buildings":
                    entityParams.campus_ids = [id];
                    break;
                case "campuses":
                    entityParams.sector_ids = [id];
                    break;
                case "sectors":
                    entityParams.client_ids = [id];
                    break;
                case "clients":
                    entityParams.consultancy_ids = [id];
                    break;
                default:
                    break;
            }
        }

        const params = { ...master_filters, ...entityParams, chart_view, break_down_type: key };
        this.props.getEventBreakdownByHierarchy(params, key);
    };
    render() {
        const {
            counts,
            event_breakdown,
            completed_events_breakdown,
            incomplete_events_breakdown,
            calendar_analysis,
            top_10_completed_with_failures,
            isFullScreen,
            fullScreenChart,
            chart_view,
            shownChildLevels
        } = this.state;
        const { isLoading, showEntityCount } = this.props;
        if (!checkPermission("menu", "dashboard", null))
            return (
                <section className="cont-ara">
                    <div className="fst content-main"></div>
                </section>
            );

        return (
            <section className="cont-ara">
                <LoadingOverlay active={isLoading} spinner={<Loader />}>
                    <div className="fst content-main">
                        <TopSlider />
                        <div className="gry-area">
                            <div className="content-area">
                                {showEntityCount ? (
                                    <div className="info-outer">
                                        {counts && counts.length
                                            ? counts.map(item => {
                                                  return <EntityCount countData={item} />;
                                              })
                                            : null}
                                    </div>
                                ) : null}
                                <div className="graph-otr">
                                    {((isFullScreen && fullScreenChart === "event_breakdown") || !isFullScreen) && (
                                        <EventBreakdownChart
                                            eventBreakdownData={event_breakdown}
                                            toggleSmartReportModal={this.smartReportHandler}
                                            fullViewHandler={this.fullViewHandler}
                                            isFullScreen={isFullScreen}
                                            fullScreenChart={fullScreenChart}
                                            handleViewBreakdown={this.handleViewBreakdown}
                                            sectionKey={"main"}
                                            expanded={shownChildLevels?.main?.expanded}
                                        />
                                    )}
                                    {((isFullScreen && fullScreenChart === "completed_events_breakdown") || !isFullScreen) && (
                                        <CompletedEventBreakdownChart
                                            completedEventBreakdownData={completed_events_breakdown}
                                            toggleSmartReportModal={this.smartReportHandler}
                                            isFullScreen={isFullScreen}
                                            fullScreenChart={fullScreenChart}
                                            fullViewHandler={this.fullViewHandler}
                                        />
                                    )}
                                    {((isFullScreen && fullScreenChart === "incomplete_events_breakdown") || !isFullScreen) && (
                                        <IncompletedEventBreakdownChart
                                            IncompletedEventBreakdownData={incomplete_events_breakdown}
                                            toggleSmartReportModal={this.smartReportHandler}
                                            isFullScreen={isFullScreen}
                                            fullScreenChart={fullScreenChart}
                                            fullViewHandler={this.fullViewHandler}
                                        />
                                    )}
                                    {/* Event chart child breakdown */}
                                    {hierarchyLevels.map(section => (
                                        <>
                                            {shownChildLevels?.[section.key]?.visible && (
                                                <EventBreakdownChild
                                                    collapseRefs={this.collapseRefs}
                                                    isFullScreen={isFullScreen}
                                                    fullScreenChart={fullScreenChart}
                                                    fullViewHandler={this.fullViewHandler}
                                                    smartReportHandler={this.smartReportHandler}
                                                    section={section}
                                                    handleViewBreakdown={this.handleViewBreakdown}
                                                    sectionData={shownChildLevels?.[section.key]}
                                                />
                                            )}
                                        </>
                                    ))}
                                    <div className="row w-100 m-0">
                                        {((isFullScreen && fullScreenChart === "calendar_analysis") || !isFullScreen) && (
                                            <CalenderAnalysisChart
                                                calenderAnalysisData={calendar_analysis}
                                                toggleSmartReportModal={this.smartReportHandler}
                                                isFullScreen={isFullScreen}
                                                fullScreenChart={fullScreenChart}
                                                fullViewHandler={this.fullViewHandler}
                                            />
                                        )}
                                        {((isFullScreen && fullScreenChart === "top_10_completed_with_failures") || !isFullScreen) && (
                                            <CompletedWithFailuresChart
                                                completedWithFailureData={top_10_completed_with_failures}
                                                toggleSmartReportModal={this.smartReportHandler}
                                                isFullScreen={isFullScreen}
                                                fullScreenChart={fullScreenChart}
                                                fullViewHandler={this.fullViewHandler}
                                                toggleChartView={this.toggleChartView}
                                                chart_view={chart_view}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderSmartReportModal()}
                </LoadingOverlay>
            </section>
        );
    }
}

const mapStateToProps = state => {
    const { dashboardReducer } = state;
    return { dashboardReducer };
};

export default withRouter(connect(mapStateToProps, { ...actions })(index));
